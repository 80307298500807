import {
  Alert,
  Box,
  Button,
  FormLabel,
  Snackbar,
  SnackbarCloseReason,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import axios from 'axios';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

/**
 * パスワード編集画面
 * @returns パスワード編集のJSX
 */
const PasswordEdit = () => {
  const [nowPassword, setNowPassword] = useState<any>('');
  const [newPassword, setNewPassword] = useState<any>('');
  const [checkPassword, setCheckPassword] = useState<any>('');
  const [open, setOpen] = useState(false);
  const [openNowPasswordAlert, setOpenNowPasswordAlert] = useState(false);
  const [openNewPasswordAlert, setOpenNewPasswordAlert] = useState(false);

  const auth = getAuth();

  //変更ボタンを押したときの処理　パスワードを変更する
  const handleClick = async () => {
    const user = auth.currentUser;

    if (user != null && user.email != null) {
      const cred = EmailAuthProvider.credential(user.email, nowPassword);

      try {
        await reauthenticateWithCredential(user, cred);

        // User entered correct credentials
      } catch (e) {
        console.log(e);
        // 現在パスワードが間違っている場合、アラートを表示
        setOpenNowPasswordAlert(true);
        return;
      }
      // 新しいパスワードが空、もしくは確認用と異なる場合はアラート表示
      if (newPassword !== '' && newPassword !== checkPassword) {
        setOpenNewPasswordAlert(true);
        return;
      }

      const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };
      // パスワード変更処理
      try {
        await axios.post(
          api_base_url + 'agencyPortalUpdateUserPassword',
          { data: { new_password: newPassword } },
          config,
        );
      } catch (e) {
        console.log(e);
        alert('変更処理が失敗しました。');
        return;
      }
      setOpen(true);
    }
  };

  // アラートを閉じる処理
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setOpenNowPasswordAlert(false);
    setOpenNewPasswordAlert(false);
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormLabel required htmlFor="password">
              現在のパスワード
            </FormLabel>
          </Box>
          <TextField
            name="now_password"
            type="password"
            id="now_password"
            autoFocus
            required
            fullWidth
            variant="outlined"
            value={nowPassword}
            onChange={(e: any) => setNowPassword(e.target.value)}
          />
        </Grid>
        <Grid size={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormLabel required htmlFor="password">
              新しいパスワード
            </FormLabel>
          </Box>
          <TextField
            name="new_password"
            type="password"
            id="new_password"
            required
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={(e: any) => setNewPassword(e.target.value)}
          />
        </Grid>
        <Grid size={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormLabel required htmlFor="password">
              新しいパスワード（確認）
            </FormLabel>
          </Box>
          <TextField
            name="check_password"
            type="password"
            id="check_password"
            required
            fullWidth
            variant="outlined"
            value={checkPassword}
            onChange={(e: any) => setCheckPassword(e.target.value)}
          />
        </Grid>
        <Grid size={12} textAlign={'right'}>
          <Button variant="contained" onClick={handleClick} sx={{ bgcolor: '#FF4455' }}>
            変更する
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          変更しました。
        </Alert>
      </Snackbar>
      <Snackbar
        open={openNowPasswordAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
          現在のパスワードが間違っています。
        </Alert>
      </Snackbar>
      <Snackbar
        open={openNewPasswordAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
          新しいパスワードが間違っています。
        </Alert>
      </Snackbar>
    </>
  );
};

export default PasswordEdit;
