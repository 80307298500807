import {
  CircularProgress,
  Grid2 as Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import AccountType from '../../types/AccountType';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';

/**
 * アカウント画面
 * @returns アカウントのJSX
 */
const Account = () => {
  const [account, setAccount] = useState<AccountType | null>(null);

  useEffect(() => {
    const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
    axios.get(api_base_url + 'agencyPortalGetAgencyInformation').then((response) => {
      const data: AccountType = response.data;
      setAccount(data);
    });
  }, []);

  if (account == null) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Typography sx={{ textAlign: 'center', fontSize: 25, fontWeight: 'medium' }}>
        登録情報
      </Typography>
      <Typography sx={{ textAlign: 'center' }}>
        ご登録情報の一部はエージェンシー様側では変更できません。
      </Typography>
      <Typography sx={{ textAlign: 'center' }}>
        変更権限がないものにつきましては変更依頼フォームよりご依頼ください。
      </Typography>
      <Typography sx={{ textAlign: 'center' }}>
        <Link href="https://forms.gle/BMHHDKrK944suKf27">変更依頼フォーム</Link>
      </Typography>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              会社名
            </TableCell>
            <TableCell>{account.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              法人名(フリガナ)
            </TableCell>
            <TableCell>{account.name_furigana}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              代表者役職
            </TableCell>
            <TableCell>{account.representative_position}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              代表者名
            </TableCell>
            <TableCell>{account.representative_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              本社所在地
            </TableCell>
            <TableCell>{account.head_office_location}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              電話番号
            </TableCell>
            <TableCell>{account.phone_number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              ご担当者名
            </TableCell>
            <TableCell>{account.contact_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              ご担当者名（フリガナ）
            </TableCell>
            <TableCell>{account.contact_name_furigana}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              メールアドレス
            </TableCell>
            <TableCell>{account.email_address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              パスワード
            </TableCell>
            <TableCell>●●●●●</TableCell>
            <TableCell
              sx={{
                maxWidth: 200,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Link component={RouterLink} to="/passwordEdit">
                <LockOpenIcon fontSize="small" />
                変更
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              口座情報
            </TableCell>
            <TableCell>
              {account.bank_account.bank_name}
              {account.bank_account.branch_name}/{account.bank_account.account_type}/
              {account.bank_account.account_number}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default Account;
