import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import PaymentType from '../../types/PaymentType';
import Grid from '@mui/material/Grid2';

/**
 * 支払い画面
 * @returns 支払いレポートのJSX
 */
const PaymentReport = () => {
  const [payments, setPayments] = useState<Array<PaymentType> | null>(null);

  /**
   * 初期処理　支払いレポートをAPIから取得
   */
  useEffect(() => {
    const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
    axios.get(api_base_url + 'agencyPortalGetPaymentReport').then((response) => {
      const data: Array<PaymentType> = response.data;
      setPayments(data);
    });
  }, []);

  // 支払いレポートがAPIから取れていない段階はロード中を表示
  if (payments == null) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  // 支払いレポートが空の場合の表示
  if (payments.length === 0) {
    return <>支払いレポートがありません。</>;
  }

  return (
    <>
      <Box p={2}>
        <Typography sx={{ textAlign: 'left' }}>
          成功報酬の支払レポートを月毎に確認できます。
          （未払い報酬の合計額が3,000円に満たない場合、3,000円になるまで次月以降に繰越されます。）
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>確定年月</TableCell>
              <TableCell>成功報酬金額</TableCell>
              <TableCell>振り込み状況</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((row) => (
              <TableRow
                key={row.year_month}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.year_month}
                </TableCell>
                <TableCell>{row.amount.toLocaleString()}円</TableCell>
                <TableCell>
                  {(() => {
                    if (row.status === 'COMPLETED') {
                      return <>振込済</>;
                    } else if (row.status === 'SCHEDULED') {
                      return <>振込予定</>;
                    } else if (row.status === 'PENDING') {
                      return <>繰越</>;
                    }
                  })()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <Typography sx={{ textAlign: 'right' }} fontSize={10}>
          成功報酬：登録したクリエイターが配信するライブ配信において、視聴者からのギフト送信によって獲得した合計収益の３０％に相当する金額
        </Typography>
      </Box>
    </>
  );
};

export default PaymentReport;
