import { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../FirebaseConfig';
import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext<any>('');

export function useAuthContext() {
  return useContext(AuthContext);
}

/**
 * ログイン情報からアクセストークンを取得する
 *
 * @returns アクセストークン
 */
export const getUserToken = async () => {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        const token = await getIdToken(user);
        resolve(token);
      } else {
        console.log('User not logged in');
        resolve(null);
      }
      unsub();
    });
  });
};

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<any>('');

  const logout = () => {
    return auth.signOut();
  };

  const value = {
    user,
    logout,
  };

  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => {
      unsubscribed();
    };
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
