import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';

const drawerWidth = 240;

interface Props {
  handleDrawerToggle: () => void;
  openMenu: boolean;
}
/**
 * ログイン時の読み込み途中で出すダミーのヘッダー部のJSX
 *
 * @param param0
 * @returns
 */
const DummyHeader = ({ handleDrawerToggle, openMenu }: Props) => {
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          bgcolor: 'black',
          width: { sm: openMenu ? `calc(100% - ${drawerWidth}px)` : `calc(100%)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={openMenu ? { mr: 2, display: { sm: 'none' } } : { mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            data-testid="title"
            variant="h6"
            style={{ textDecoration: 'none' }}
            color="inherit"
            noWrap
          ></Typography>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton sx={{ ml: 1 }} color="inherit">
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default DummyHeader;
