import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CreatorType from '../../types/CreatorType';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid2';

/**
 * クリエイター一覧画面
 * @returns クリエイター一覧のJSX
 */
const CreatorList = () => {
  const [creators, setCreators] = useState<Array<CreatorType> | null>(null);

  /**
   * 配信時間（秒）を時刻形式に変換する
   *
   * @param seconds
   * @returns
   */
  function secToHMS(seconds: number) {
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    let hh;
    // hour が3桁以上の場合は左0埋めをしない
    if (hour < 100) {
      hh = `00${hour}`.slice(-2);
    } else {
      hh = hour;
    }
    const mm = `00${min}`.slice(-2);
    const ss = `00${sec}`.slice(-2);
    const time = `${hh}:${mm}:${ss}`;
    return time;
  }

  /**
   * 初期処理　クリエイター一覧をAPIから取得
   */
  useEffect(() => {
    const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
    axios.get(api_base_url + 'agencyPortalGetAgencyCreators').then((response) => {
      const data: Array<CreatorType> = response.data;
      setCreators(data);
    });
  }, []);

  // クリエイター一覧がAPIから取れていない段階はロード中を表示
  if (creators == null) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  // クリエイター一覧が空の場合の表示
  if (creators.length === 0) {
    return <>クリエイター一覧がありません。</>;
  }

  return (
    <>
      <Box p={2}>
        <Typography sx={{ textAlign: 'left' }}>
          登録（申請）中のクリエイター一覧です。「詳細を見る」より、それぞれのクリエイターの配信実績を月毎に確認できます。
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>氏名</TableCell>
              <TableCell>フリガナ</TableCell>
              <TableCell align="right">年齢</TableCell>
              <TableCell>電話番号</TableCell>
              <TableCell>
                審査
                <br />
                ステータス
              </TableCell>
              <TableCell>招待コード</TableCell>
              <TableCell>
                アプリ登録
                <br />
                ステータス
              </TableCell>
              <TableCell>
                今月の
                <br />
                配信時間
              </TableCell>
              <TableCell>
                今月の
                <br />
                ギフト収益
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creators.map((row) => (
              <TableRow
                key={row.creator_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.name_furigana}</TableCell>
                <TableCell align="right">{row.age}</TableCell>
                <TableCell>{row.phone_number}</TableCell>
                <TableCell>{row.approved ? '承認済み' : '承認待ち'}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.activated ? '登録済み' : '未登録'}</TableCell>
                <TableCell>{secToHMS(row.live_history?.total_time_seconds)}</TableCell>
                <TableCell>{row.live_history?.total_revenue.toLocaleString()}円</TableCell>
                <TableCell>
                  {row.activated && (
                    <Button
                      variant="contained"
                      size="small"
                      component={RouterLink}
                      to={'/achievements/' + row.creator_id}
                      sx={{ bgcolor: '#FF4455' }}
                    >
                      詳細を見る
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CreatorList;
