import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

//ログイン認証に必要なFirebaseの設定を読み込み
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRA_API,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRA_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
