import React from 'react';
import { useAuthContext } from './AuthContext';
import { Login } from '../components/index';

/**
 * 認証がない場合にログインページを表示
 * @param param0
 * @returns
 */
export const ProtectedRoute = ({ children }: any) => {
  const { user } = useAuthContext();

  if (user == null) {
    // ユーザーが認証されていない場合は、ログインページを表示
    return <Login />;
  }

  return children;
};
