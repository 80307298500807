import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Snackbar,
  SnackbarCloseReason,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import CreatorType from '../../types/CreatorType';
import axios from 'axios';

/**
 * クリエイター申請画面
 * @returns クリエイター申請のJSX
 */
const ApplyCreator = () => {
  const [open, setOpen] = useState(false);
  const [creator, setCreator] = useState<CreatorType>(new CreatorType());
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  //バリデートチェック用のstate
  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [nameFriganaError, setNameFriganaError] = useState(false);
  const [nameFriganaErrorMessage, setNameFriganaErrorMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [ageError, setAgeError] = useState(false);
  const [ageErrorMessage, setAgeErrorMessage] = useState('');
  const [occupationError, setOccupationError] = useState(false);
  const [occupationErrorMessage, setOccupationErrorMessage] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  /**
   * バリデートチェック
   * @returns チェック結果 true/false
   */
  const validateInputs = (): boolean => {
    let isValid = true;

    if (!creator.name.replaceAll(' ', '').replaceAll('　', '')) {
      setNameError(true);
      setNameErrorMessage('氏名は必須です。');
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    if (!creator.name_furigana.replaceAll(' ', '').replaceAll('　', '')) {
      setNameFriganaError(true);
      setNameFriganaErrorMessage('フリガナは必須です。');
      isValid = false;
    } else {
      setNameFriganaError(false);
      setNameFriganaErrorMessage('');
    }

    //メールアドレスチェック
    if (!creator.email_address || !/\S+@\S+\.\S+/.test(creator.email_address)) {
      setEmailError(true);
      setEmailErrorMessage('メールアドレスの形式が正しくありません。');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!creator.age) {
      setAgeError(true);
      setAgeErrorMessage('年齢は必須です。');
      isValid = false;
    } else if (creator.age >= 100 || creator.age < 10) {
      setAgeError(true);
      setAgeErrorMessage('年齢は2桁の正の整数です。');
      isValid = false;
    } else {
      setAgeError(false);
      setAgeErrorMessage('');
    }

    if (!creator.occupation) {
      setOccupationError(true);
      setOccupationErrorMessage('職業は必須です。');
      isValid = false;
    } else {
      setOccupationError(false);
      setOccupationErrorMessage('');
    }

    let phone_number = creator.phone_number.replaceAll('-', '');
    if (creator.phone_number.startsWith('0')) {
      phone_number = phone_number.replace('0', '+81');
    }
    const phoneRegex9 = /^\+81[0-9]{9}$/;
    const phoneRegex10 = /^\+81[0-9]{10}$/;
    if (!phone_number) {
      setPhoneError(true);
      setPhoneErrorMessage('電話番号は必須です。');
      isValid = false;
    } else if (!phoneRegex9.test(phone_number) && !phoneRegex10.test(phone_number)) {
      setPhoneError(true);
      setPhoneErrorMessage('電話番号の形式で記載ください。');
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    return isValid;
  };

  /**
   * 申請ボタンを押したときの処理
   *
   * バリデートチェックがOKの場合、APIを呼び出す
   */
  const handleClick = () => {
    if (validateInputs()) {
      //ボタンを非活性にする
      setButtonDisable(true);
      const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };
      creator.phone_number = creator.phone_number.replaceAll('-', '');
      // 電話番号を国際電話番号形式に変換
      if (creator.phone_number.startsWith('0')) {
        creator.phone_number = creator.phone_number.replace('0', '+81');
      }

      // 氏名とフリガナからスペースを削除
      creator.name = creator.name.replaceAll(' ', '').replaceAll('　', '');
      creator.name_furigana = creator.name_furigana.replaceAll(' ', '').replaceAll('　', '');

      axios
        .post(api_base_url + 'agencyPortalPostCreatorRegistration', { data: creator }, config)
        .then((response) => {
          // 処理成功したので初期化してメッセージを表示
          setCreator(new CreatorType());
          setOpen(true);
        })
        .finally(() => {
          setButtonDisable(false);
        });
    }
  };

  /**
   * 申請完了のポップアップを閉じる関数
   *
   * @param event
   * @param reason
   * @returns
   */
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /**
   * 入力項目の変更を反映する関数
   *
   * @param field_name 変更したいフィールドの値
   * @param e イベント
   */
  const setCreatorField = (
    field_name: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCreator((prev) => {
      const tmp: any = { ...prev };
      tmp[field_name] = e.target.value;
      return tmp;
    });
  };

  return (
    <>
      <Box p={2}>
        <Typography sx={{ textAlign: 'left' }}>
          新規クリエイターの登録申請を行うことができます。申請状況は「クリエイター一覧」から確認できます。
        </Typography>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={6}>
          <TextField
            error={nameError}
            helperText={nameErrorMessage}
            fullWidth
            label="氏名"
            value={creator.name}
            onChange={(e) => setCreatorField('name', e)}
          />
        </Grid>
        <Grid size={6}>
          <TextField
            error={nameFriganaError}
            helperText={nameFriganaErrorMessage}
            fullWidth
            label="フリガナ"
            value={creator.name_furigana}
            onChange={(e) => setCreatorField('name_furigana', e)}
          />
        </Grid>
        <Grid size={6}>
          <TextField
            error={emailError}
            helperText={emailErrorMessage}
            fullWidth
            label="メールアドレス"
            value={creator.email_address}
            onChange={(e) => setCreatorField('email_address', e)}
          />
        </Grid>
        <Grid size={6}>
          <TextField
            error={ageError}
            helperText={ageErrorMessage}
            label="年齢"
            fullWidth
            type="number"
            value={creator.age}
            onChange={(e) => setCreatorField('age', e)}
          />
        </Grid>
        <Grid size={6}>
          <TextField
            error={occupationError}
            helperText={occupationErrorMessage}
            label="職業"
            fullWidth
            value={creator.occupation}
            onChange={(e) => setCreatorField('occupation', e)}
          />
        </Grid>
        <Grid size={6}>
          <TextField
            label="電話番号"
            error={phoneError}
            helperText={phoneErrorMessage}
            fullWidth
            value={creator.phone_number}
            onChange={(e) => setCreatorField('phone_number', e)}
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }}>その他審査に必要な情報</Divider>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={12}>
          <Typography variant="subtitle2" gutterBottom>
            SNSアカウント&フォロワー数 (X, TikTok, Instagramすべて記入）
          </Typography>
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            label="XのアカウントID"
            value={creator.entry_information.x_account.id_str}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.x_account.id_str = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            type="number"
            label="Xのフォロワー数"
            value={creator.entry_information.x_account.follwers_num}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: any = { ...prev };
                tmp.entry_information.x_account.follwers_num = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            label="TikTokのアカウントID"
            value={creator.entry_information.tiktok_account.id_str}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.tiktok_account.id_str = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            type="number"
            label="TikTokのフォロワー数"
            value={creator.entry_information.tiktok_account.follwers_num}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: any = { ...prev };
                tmp.entry_information.tiktok_account.follwers_num = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            label="InstagramのアカウントID"
            value={creator.entry_information.instagram_account.id_str}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.instagram_account.id_str = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            type="number"
            label="Instagramのフォロワー数"
            value={creator.entry_information.instagram_account.follwers_num}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: any = { ...prev };
                tmp.entry_information.instagram_account.follwers_num = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            type="number"
            label="1日に配信可能な時間の平均"
            value={creator.entry_information.live_schedule.average_hours_num_per_day}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: any = { ...prev };
                tmp.entry_information.live_schedule.average_hours_num_per_day = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={6} />
        <FormLabel component="legend">配信可能な(予定の)曜日と時間帯</FormLabel>
        <Grid size={12}>
          <TextField
            label="月曜の時間帯"
            size="small"
            value={creator.entry_information.live_schedule.calender.monday}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.live_schedule.calender.monday = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="火曜の時間帯"
            size="small"
            value={creator.entry_information.live_schedule.calender.tuesday}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.live_schedule.calender.tuesday = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="水曜の時間帯"
            size="small"
            value={creator.entry_information.live_schedule.calender.wednesday}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.live_schedule.calender.wednesday = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="木曜の時間帯"
            size="small"
            value={creator.entry_information.live_schedule.calender.thursday}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.live_schedule.calender.thursday = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="金曜の時間帯"
            size="small"
            value={creator.entry_information.live_schedule.calender.friday}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.live_schedule.calender.friday = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="土曜の時間帯"
            size="small"
            value={creator.entry_information.live_schedule.calender.saturday}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.live_schedule.calender.saturday = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="日曜の時間帯"
            size="small"
            value={creator.entry_information.live_schedule.calender.sunday}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.live_schedule.calender.sunday = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={4}>
          <FormControlLabel
            control={
              <Checkbox
                value={creator.entry_information.live_experience.experience}
                onChange={(e) =>
                  setCreator((prev) => {
                    const tmp: CreatorType = { ...prev };
                    tmp.entry_information.live_experience.experience = e.target.checked;
                    if (!e.target.checked) {
                      tmp.entry_information.live_experience.appname = '';
                      tmp.entry_information.live_experience.achievements = '';
                    }
                    return tmp;
                  })
                }
              />
            }
            label="ライブ配信アプリの経験の有無"
          />
        </Grid>
        <Grid size={4}>
          <TextField
            fullWidth
            label="配信アプリの名称"
            value={creator.entry_information.live_experience.appname}
            disabled={!creator.entry_information.live_experience.experience}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.live_experience.appname = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={4}>
          <TextField
            fullWidth
            label="実績"
            value={creator.entry_information.live_experience.achievements}
            disabled={!creator.entry_information.live_experience.experience}
            onChange={(e) =>
              setCreator((prev) => {
                const tmp: CreatorType = { ...prev };
                tmp.entry_information.live_experience.achievements = e.target.value;
                return tmp;
              })
            }
          />
        </Grid>
        <Grid size={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label="最短開始日"
                format="YYYY/MM/DD"
                slotProps={{ calendarHeader: { format: 'YYYY年MM月' } }}
                value={creator.entry_information.earliest_start_date}
                onChange={(e) =>
                  setCreator((prev) => {
                    const tmp: CreatorType = { ...prev };
                    tmp.entry_information.earliest_start_date = e;
                    return tmp;
                  })
                }
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid size={12} textAlign={'right'}>
          <Button
            variant="contained"
            disabled={buttonDisable}
            onClick={handleClick}
            sx={{ bgcolor: '#FF4455' }}
          >
            申請する
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          申請しました。
        </Alert>
      </Snackbar>
    </>
  );
};

export default ApplyCreator;
