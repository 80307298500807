import React, { useEffect } from 'react';

import axios from 'axios';
import { getUserToken } from './AuthContext';
import DummyMain from './DummyMain';

type Props = {
  children: React.ReactNode;
};

/**
 * 初期処理でアクセストークンをaxiosのリクエストに格納
 *
 * @param props children
 * @returns
 */
export const AxiosConfig = (props: Props) => {
  const { children } = props;

  const [token, setToken] = React.useState(null);

  useEffect(() => {
    getUserToken().then((data) => {
      setToken(data as any);
    });
  }, []);

  axios.interceptors.request.use((request) => {
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request;
  });

  if (!token) return <DummyMain />;
  return <>{children}</>;
};
