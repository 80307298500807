import { Dayjs } from 'dayjs';

class CreatorType {
  creator_id!: string; //ユーザを特定するID
  name: string; //名前
  name_furigana: string; //フリガナ
  email_address: string; //メール
  age: number | ''; //年齢
  occupation: string; //職業
  phone_number: string; //電話番号

  entry_information: {
    x_account: {
      id_str: string;
      follwers_num: number | null;
    };
    instagram_account: {
      id_str: string;
      follwers_num: number | null;
    };
    tiktok_account: {
      id_str: string;
      follwers_num: number | null;
    };
    live_schedule: {
      days_num_per_month: number | null;
      average_hours_num_per_day: number | null;
      hours_num_per_month: number | null;
      calender: {
        monday: string;
        tuesday: string;
        wednesday: string;
        thursday: string;
        friday: string;
        saturday: string;
        sunday: string;
      };
    };
    live_experience: {
      experience: boolean;
      appname: string;
      achievements: string;
    };
    earliest_start_date: Dayjs | null;
  };

  approved!: boolean; //承認ステータス
  code!: string; //招待コード
  activated!: boolean;

  live_history: {
    total_time_seconds: number;
    total_days_num: number;
    total_revenue: number;
  };
  constructor() {
    this.name = '';
    this.name_furigana = '';
    this.email_address = '';
    this.occupation = '';
    this.age = '';
    this.phone_number = '';
    this.entry_information = {
      x_account: {
        id_str: '',
        follwers_num: null,
      },
      instagram_account: {
        id_str: '',
        follwers_num: null,
      },
      tiktok_account: {
        id_str: '',
        follwers_num: null,
      },
      live_schedule: {
        days_num_per_month: null,
        average_hours_num_per_day: null,
        hours_num_per_month: null,
        calender: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
        },
      },
      live_experience: {
        experience: false,
        appname: '',
        achievements: '',
      },
      earliest_start_date: null,
    };
    this.live_history = {
      total_time_seconds: 0,
      total_days_num: 0,
      total_revenue: 0,
    };
  }
}

export default CreatorType;
