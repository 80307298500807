class AccountType {
  name: string; //法人名
  name_furigana: string; //法人名（フリガナ）
  representative_position: string; //代表者役職
  representative_name: string; //代表者名前
  head_office_location: string; //本社所在地
  phone_number: string; //電話番号
  contact_name: string; //担当者名
  contact_name_furigana: string; //担当者名（フリガナ）
  email_address: string; //メールアドレス
  bank_account: {
    bank_name: string;
    account_number: string;
    branch_name: string;
    account_type: string; //普通or当座
  };
  constructor() {
    this.name = '';
    this.name_furigana = '';
    this.representative_position = '';
    this.representative_name = '';
    this.head_office_location = '';
    this.phone_number = '';
    this.contact_name = '';
    this.contact_name_furigana = '';
    this.email_address = '';
    this.bank_account = {
      bank_name: '',
      account_number: '',
      branch_name: '',
      account_type: '',
    };
  }
}

export default AccountType;
