import {
  AppBar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import axios from 'axios';
import AccountType from '../types/AccountType';
import { useAuthContext } from './AuthContext';
import DummyMain from './DummyMain';

/**
 * 初回ログイン時に、エイジェンシー情報の登録画面を表示
 * @param param0
 * @returns
 */
const AgencyRegister = ({ children }: any) => {
  const [account, setAccount] = useState<AccountType>(new AccountType());
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [firstLogin, setFirstLogin] = useState<any>(null);
  const { logout } = useAuthContext();

  //バリデートチェック用のstate
  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [nameFriganaError, setNameFriganaError] = useState(false);
  const [nameFriganaErrorMessage, setNameFriganaErrorMessage] = useState('');
  const [representativePositionError, setRepresentativePositionError] = useState(false);
  const [representativePositionErrorMessage, setRepresentativePositionErrorMessage] = useState('');
  const [representativeNameError, setRepresentativeNameError] = useState(false);
  const [representativeNameErrorMessage, setRepresentativeNameErrorMessage] = useState('');
  const [locationError, setLocationError] = useState(false);
  const [locationErrorMessage, setLocationErrorMessage] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [contactNameError, setContactNameError] = useState(false);
  const [contactNameErrorMessage, setContactNameErrorMessage] = useState('');
  const [contactNameFriganaError, setContactNameFriganaError] = useState(false);
  const [contactNameFriganaErrorMessage, setContactNameFriganaErrorMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [bankNameError, setBankNameError] = useState(false);
  const [bankNameErrorMessage, setBankNameErrorMessage] = useState('');
  const [accountNumberError, setAccountNumberError] = useState(false);
  const [accountNumberErrorMessage, setAccountNumberErrorMessage] = useState('');
  const [accountTypeError, setAccoutTypeError] = useState(false);
  const [accountTypeErrorMessage, setAccoutTypeErrorMessage] = useState('');
  const [branchNameError, setBranchNameError] = useState(false);
  const [branchNameErrorMessage, setBranchNameErrorMessage] = useState('');

  /**
   * バリデートチェック
   * @returns チェック結果 true/false
   */
  const validateInputs = (): boolean => {
    let isValid = true;

    if (!account.name) {
      setNameError(true);
      setNameErrorMessage('法人名は必須です。');
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    if (!account.name_furigana) {
      setNameFriganaError(true);
      setNameFriganaErrorMessage('法人名(フリガナ)は必須です。');
      isValid = false;
    } else {
      setNameFriganaError(false);
      setNameFriganaErrorMessage('');
    }

    if (!account.representative_position) {
      setRepresentativePositionError(true);
      setRepresentativePositionErrorMessage('代表者役職は必須です。');
      isValid = false;
    } else {
      setRepresentativePositionError(false);
      setRepresentativePositionErrorMessage('');
    }

    if (!account.representative_name) {
      setRepresentativeNameError(true);
      setRepresentativeNameErrorMessage('代表者名は必須です。');
      isValid = false;
    } else {
      setRepresentativeNameError(false);
      setRepresentativeNameErrorMessage('');
    }

    if (!account.head_office_location) {
      setLocationError(true);
      setLocationErrorMessage('本社所在地は必須です。');
      isValid = false;
    } else {
      setLocationError(false);
      setLocationErrorMessage('');
    }

    let phone_number = account.phone_number.replaceAll('-', '');
    if (account.phone_number.startsWith('0')) {
      phone_number = phone_number.replace('0', '+81');
    }
    const phoneRegex9 = /^\+81[0-9]{9}$/;
    const phoneRegex10 = /^\+81[0-9]{10}$/;
    if (!phone_number) {
      setPhoneError(true);
      setPhoneErrorMessage('電話番号は必須です。');
      isValid = false;
    } else if (!phoneRegex9.test(phone_number) && !phoneRegex10.test(phone_number)) {
      setPhoneError(true);
      setPhoneErrorMessage('電話番号の形式で記載ください。');
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    if (!account.contact_name) {
      setContactNameError(true);
      setContactNameErrorMessage('ご担当者名は必須です。');
      isValid = false;
    } else {
      setContactNameError(false);
      setContactNameErrorMessage('');
    }

    if (!account.contact_name_furigana) {
      setContactNameFriganaError(true);
      setContactNameFriganaErrorMessage('ご担当者名（フリガナ）は必須です。');
      isValid = false;
    } else {
      setContactNameFriganaError(false);
      setContactNameFriganaErrorMessage('');
    }

    //メールアドレスチェック
    if (!account.email_address || !/\S+@\S+\.\S+/.test(account.email_address)) {
      setEmailError(true);
      setEmailErrorMessage('メールアドレスの形式が正しくありません。');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!account.bank_account.bank_name) {
      setBankNameError(true);
      setBankNameErrorMessage('銀行名は必須です。');
      isValid = false;
    } else {
      setBankNameError(false);
      setBankNameErrorMessage('');
    }

    if (!account.bank_account.branch_name) {
      setBranchNameError(true);
      setBranchNameErrorMessage('支店名は必須です。');
      isValid = false;
    } else {
      setBranchNameError(false);
      setBranchNameErrorMessage('');
    }

    if (!account.bank_account.account_type) {
      setAccoutTypeError(true);
      setAccoutTypeErrorMessage('口座タイプ（普通or当座）は必須です。');
      isValid = false;
    } else if (
      account.bank_account.account_type !== '普通' &&
      account.bank_account.account_type !== '当座'
    ) {
      setAccoutTypeError(true);
      setAccoutTypeErrorMessage('普通もしくは当座と入力してください。');
      isValid = false;
    } else {
      setAccoutTypeError(false);
      setAccoutTypeErrorMessage('');
    }

    if (!account.bank_account.account_number) {
      setAccountNumberError(true);
      setAccountNumberErrorMessage('口座番号は必須です。');
      isValid = false;
    } else if (
      account.bank_account.account_number.length !== 7 &&
      account.bank_account.account_number.length !== 8
    ) {
      setAccountNumberError(true);
      setAccountNumberErrorMessage('口座番号は7桁か8桁です。');
      isValid = false;
    } else {
      setAccountNumberError(false);
      setAccountNumberErrorMessage('');
    }

    return isValid;
  };

  async function handleLogout() {
    try {
      await logout();
      window.location.href = '/';
    } catch {
      console.log('ログアウト失敗');
    }
  }

  // 保存ボタンを押したときにAPIを読んで登録しに行く
  const handleClick = () => {
    if (validateInputs()) {
      //ボタンを非活性にする
      setButtonDisable(true);
      const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };
      account.phone_number = account.phone_number.replaceAll('-', '');
      if (account.phone_number.startsWith('0')) {
        account.phone_number = account.phone_number.replace('0', '+81');
      }
      axios
        .post(api_base_url + 'agencyPortalPostAgencyRegistration', { data: account }, config)
        .then((response) => {
          setFirstLogin({ first_login: false });
        })
        .finally(() => {
          setButtonDisable(false);
        });
    }
  };

  const setAccountField = (
    field_name: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const tmp: any = { ...account };
    tmp[field_name] = e.target.value;
    setAccount(tmp);
  };

  useEffect(() => {
    const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
    axios.get(api_base_url + 'agencyPortalGetFirstLoginOrNot').then((response) => {
      const data: boolean = response.data;
      setFirstLogin(data);
    });
  }, []);

  if (firstLogin == null) {
    // 読み込み前はloadingと出す
    return <DummyMain />;
  }
  if (firstLogin.first_login) {
    // 初回ログイン時の場合は、エイジェンシー情報を登録する画面を出す

    return (
      <>
        <AppBar
          position="fixed"
          sx={{
            bgcolor: 'black',
          }}
        >
          <Toolbar>
            <Typography
              data-testid="title"
              variant="h6"
              style={{ textDecoration: 'none' }}
              color="inherit"
              noWrap
            >
              エージェンシー情報登録
            </Typography>
            <div style={{ flexGrow: 1 }}></div>
            <IconButton sx={{ ml: 1 }} onClick={handleLogout} color="inherit">
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar id="top-anchor" />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid size={12}></Grid>
            <Grid size={6}>
              <TextField
                error={nameError}
                helperText={nameErrorMessage}
                fullWidth
                label="法人名"
                value={account.name}
                required
                onChange={(e) => setAccountField('name', e)}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                error={nameFriganaError}
                helperText={nameFriganaErrorMessage}
                fullWidth
                label="法人名(フリガナ)"
                required
                value={account.name_furigana}
                onChange={(e) => setAccountField('name_furigana', e)}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                error={representativePositionError}
                helperText={representativePositionErrorMessage}
                fullWidth
                label="代表者役職"
                required
                value={account.representative_position}
                onChange={(e) => setAccountField('representative_position', e)}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                error={representativeNameError}
                helperText={representativeNameErrorMessage}
                fullWidth
                label="代表者名"
                required
                value={account.representative_name}
                onChange={(e) => setAccountField('representative_name', e)}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                error={locationError}
                helperText={locationErrorMessage}
                fullWidth
                label="本社所在地"
                required
                value={account.head_office_location}
                onChange={(e) => setAccountField('head_office_location', e)}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                error={phoneError}
                helperText={phoneErrorMessage}
                fullWidth
                label="電話番号"
                required
                value={account.phone_number}
                onChange={(e) => setAccountField('phone_number', e)}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                error={contactNameError}
                helperText={contactNameErrorMessage}
                fullWidth
                label="ご担当者名"
                required
                value={account.contact_name}
                onChange={(e) => setAccountField('contact_name', e)}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                error={contactNameFriganaError}
                helperText={contactNameFriganaErrorMessage}
                fullWidth
                label="ご担当者名（フリガナ）"
                required
                value={account.contact_name_furigana}
                onChange={(e) => setAccountField('contact_name_furigana', e)}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                fullWidth
                label="メールアドレス"
                required
                value={account.email_address}
                onChange={(e) => setAccountField('email_address', e)}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }}>報酬の振込み先情報</Divider>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid size={12}></Grid>
            <Grid size={6}>
              <TextField
                error={bankNameError}
                helperText={bankNameErrorMessage}
                fullWidth
                label="銀行名"
                required
                value={account.bank_account.bank_name}
                onChange={(e) => {
                  setAccount((prev) => {
                    const tmp: AccountType = { ...prev };
                    tmp.bank_account.bank_name = e.target.value;
                    return tmp;
                  });
                }}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                error={branchNameError}
                helperText={branchNameErrorMessage}
                fullWidth
                label="支店名"
                required
                value={account.bank_account.branch_name}
                onChange={(e) => {
                  setAccount((prev) => {
                    const tmp: AccountType = { ...prev };
                    tmp.bank_account.branch_name = e.target.value;
                    return tmp;
                  });
                }}
              />
            </Grid>
            <Grid size={6}>
              <FormControl fullWidth required error={accountTypeError}>
                <InputLabel>口座タイプ（普通or当座）</InputLabel>
                <Select
                  error={accountTypeError}
                  fullWidth
                  label="口座タイプ（普通or当座）"
                  value={account.bank_account.account_type}
                  required
                  onChange={(e: any) => {
                    setAccount((prev) => {
                      const tmp: AccountType = { ...prev };
                      tmp.bank_account.account_type = e.target.value;
                      return tmp;
                    });
                  }}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="普通">普通</MenuItem>
                  <MenuItem value="当座">当座</MenuItem>
                </Select>
                <FormHelperText error>{accountTypeErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid size={6}>
              <TextField
                error={accountNumberError}
                helperText={accountNumberErrorMessage}
                fullWidth
                label="口座番号"
                required
                value={account.bank_account.account_number}
                onChange={(e) => {
                  setAccount((prev) => {
                    const tmp: AccountType = { ...prev };
                    tmp.bank_account.account_number = e.target.value;
                    return tmp;
                  });
                }}
              />
            </Grid>
          </Grid>

          <Grid size={12} textAlign={'right'}>
            <Button
              variant="contained"
              disabled={buttonDisable}
              onClick={handleClick}
              sx={{ bgcolor: '#FF4455' }}
            >
              登録する
            </Button>
          </Grid>
        </Box>
      </>
    );
  }
  return children;
};

export default AgencyRegister;
